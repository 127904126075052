import React from 'react'
import StripeDummy from '../checkout/StripeDummy';
import CheckoutModal from '../checkout/CheckoutModal';

// https://blog.logrocket.com/integrating-stripe-react-stripe-js/

export default function Checkout() {
  return (
    <div id="CareIOCheckout">
      <div className="container">
        <div className="row">
          <div className='col-md-6'>
            
            @AMAN.  <br/>
            1. PLACE THE BOOKINGCARD COMPONENT HERE. <br/>
            2. Run Sass, sal will teach you <br/>


      

          </div>
          <div className='col-md-6'>
            <StripeDummy/>
          </div>

        </div>
      </div>
        
    <CheckoutModal/>    
    </div>
  )
}

